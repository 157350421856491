import React from "react"
import { Spring, config } from "react-spring/renderprops"
import { Waypoint } from "react-waypoint"

class ProgressRing extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      progress: 0,
      entered: false
    }

    const { radius, stroke } = this.props

    this.normalizedRadius = radius - stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI

    this._handleEnter = this._handleEnter.bind(this)
    this._handleLeave = this._handleLeave.bind(this)
  }

  _handleEnter (e) {
    const { progress } = this.props

    if (this.state.entered)
      return

    this.setState({
      entered: true,
      progress: progress
    })
  }

  _handleLeave (e) {
    if (! this.state.entered)
      return

    this.setState({
      entered: false,
      progress: 0
    })
  }

  render() {
    const { radius, stroke, label } = this.props
  
    return (
      <Spring
        config={config.slow}
        from={{ value: 0 }}
        to={{ value: this.state.progress }}>
        {props => {
          const progress = props.value
          const strokeDashoffset = this.circumference - progress / 100 * this.circumference
          
          return (
            <Waypoint onEnter={this._handleEnter} onLeave={this._handleLeave}>
              <div className="relative mx-xs inline-block">
                <div className="absolute inset-0 flex items-center justify-center text-center">
                  <div>
                    <div className="text-5xl leading-tight">
                      {Math.round(progress)}
                      <span className="text-xl -mr-xs">%</span>
                    </div>
                    <div className="font-display uppercase text-xxs">{label}</div>
                  </div>
                </div>
                <svg
                  className="progress-ring"
                  height={radius * 2}
                  width={radius * 2}
                  >
                  <circle
                    stroke="#2C2421"
                    fill="transparent"
                    strokeWidth={ stroke / 2 }
                    r={ this.normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                    />
                  <circle
                    className="progress-ring-circle"
                    stroke="#B3A198"
                    fill="transparent"
                    strokeWidth={ stroke }
                    strokeDasharray={ this.circumference + ' ' + this.circumference }
                    style={ { strokeDashoffset } }
                    r={ this.normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                    />
                </svg>
              </div>
            </Waypoint>
          )
        }}
      </Spring>
    )
  }
}

ProgressRing.defaultProps = {
  radius: 90,
  stroke: 4,
}

export default ProgressRing
