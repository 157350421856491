import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import Figure from "~components/common/figure"
import ProgressRing from "~components/common/progress-ring"

const Page = ({ data }) => (
  <Layout>
    <SEO title="Unternehmen" description="High-End trifft auf Handarbeit: Ihre Konzepte und Ideen setzen wir teamorientiert mit modernster CNC-Technik und qualifizierter Handarbeit um." />
    <Container>
      <div className="xl:w-2/3 xl:mx-auto">
        <div className="gutter my-screen-lg lg:w-5/8">
          <h1>High-End trifft auf gute alte Handarbeit.</h1>
          <p>Die gestalterischen und konstruktiven Konzepte, die in Zusammenarbeit mit unseren Kunden entstehen, setzen wir mit modernster CNC-Technik und qualifizierter Handarbeit um.</p>
        </div>
      </div>
    </Container>
    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:w-7/12 lg:order-last xl:w-2/3">
          {data.imageTeam &&
          <Figure fluid={data.imageTeam.childImageSharp.fluid} alt="Teamfoto" title="Das KammTec Team" />
          }
        </div>
        <div className="gutter lg:w-5/12 xl:w-1/3">
          <h1>Hidden Champions.</h1>
          <p>Wir sind offen für die Anforderungen unserer Kunden und erschaffen als starkes Team, vor und hinter den Kulissen, stets die passenden Lösungen.   </p>
        </div>
      </div>
    </Container>
    <div className="overflow-hidden my-screen-lg">
      <div className="max-w-screen-xl mx-auto">
        <div className="gutter flex w-5/4 -mx-1/8">
          <div className="gutter w-1/2">
            <Figure fluid={data.imageProduktionshalle.childImageSharp.fluid} alt="Mitarbeiter in Produktionshalle" />
          </div>
          <div className="gutter w-1/2">
            <Figure fluid={data.imageBesprechung.childImageSharp.fluid} alt="KammTec Mitarbeiter" />
          </div>
        </div>
      </div>
    </div>
    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:w-5/12 xl:w-1/3 xl:ml-1/6">
          <h1>Holz und Stahl.</h1>
          <p>Wir sind aus dem Holz geboren und lieben die Kombination mit Stahl. In unserer hauseigenen Schlosserei machen wir fast alles möglich, egal ob individuell oder in Serie.</p>
          <div className="hidden mt-2xl shadow-2xl lg:block lg:-ml-1/6 lg:-mr-3/4 lg:relative lg:z-10">
            <Figure fluid={data.imageSchweissen.childImageSharp.fluid} alt="Mitarbeiter beim Schweißen" />
          </div>
        </div>
        <div className="my-xl max-w-md mx-auto gutter lg:max-w-none lg:mr-0 lg:w-6/12 xl:w-5/12">
          <Figure fluid={data.imageFlexen.childImageSharp.fluid} alt="Mitarbeiter beim Flexen" />
        </div>
      </div>
    </Container>
    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:order-last lg:w-5/12">
          <h1>Jede Anforderung willkommen.</h1>
          <p className="max-w-sm">Unsere Kunden sind filialisierende Ladenbauer, Einzelhändler, Messebauer, Betriebseinrichter, Architekten, Innenarchitekten und Privatkunden.
Alle finden bei uns freundliche und kompetente Ansprechpartner.</p>
        </div>
        <div className="mt-xl gutter text-center lg:w-7/12">
          <ProgressRing progress={50} label="Ladenbau" />
          <ProgressRing progress={35} label="Betriebs­einrichtung" />
          <ProgressRing progress={15} label="Innenausbau" />
        </div>
      </div>
    </Container>
    <ContactUs slug="tobias-kamm" />
  </Layout>
)

export const query = graphql`
  query {
    imageTeam: file(relativePath: { eq: "teamfoto.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 880, maxHeight: 495) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    imageProduktionshalle: file(relativePath: { eq: "mitarbeiter-in-produktionshalle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 880, maxHeight: 495) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    imageBesprechung: file(relativePath: { eq: "teambesprechung.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 880, maxHeight: 495) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    imageFlexen: file(relativePath: { eq: "mitarbeiter-flext.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 495, maxHeight: 742) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    imageSchweissen: file(relativePath: { eq: "mitarbeiter-schweisst.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 880, maxHeight: 495) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page
